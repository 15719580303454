
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBaZp4pG1W54OBgiE4ZOLPXwz-OBJZVrMk",
  authDomain: "hotelhelia-4ff98.firebaseapp.com",
  projectId: "hotelhelia-4ff98",
  storageBucket: "hotelhelia-4ff98.appspot.com",
  messagingSenderId: "527102172248",
  appId: "1:527102172248:web:9c5ce6bdfec7c62d6bfe2d",
  measurementId: "G-RR7VCFQFWC"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app)
export const storage = getStorage(app)
const analytics = getAnalytics(app);