import { getDownloadURL, ref,listAll } from "firebase/storage"
import { Navbar } from "../components/Navbar"
import { storage } from "../firebaseConfig"
import { useEffect,useState } from "react"

/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
const files = [
    {
      title: 'IMG_4985.HEIC',
      size: '3.9 MB',
      source:
        'https://images.unsplash.com/photo-1582053433976-25c00369fc93?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=512&q=80',
    },
    // More files...
  ]
  
  export default function Gallery() {
    const [restaurantImages,setRestaurant] = useState([])
    const [roomImages,setRoom] = useState([])
    
    useEffect(() =>{
        const listRef = ref(storage, 'restaurant');
        const roomRef = ref(storage,'rooms')

        listAll(listRef)
        .then((res) => {
            res.items.forEach((itemRef) => {
                getDownloadURL(itemRef)
                .then((url) =>{
                    setRestaurant(prev => [...prev,url])
                })
            })
        }).catch((error) => {
            console.log(error)
        });

        listAll(roomRef)
        .then((res) => {
            res.items.forEach((itemRef) => {
                getDownloadURL(itemRef)
                .then((url) =>{
                    setRoom(prev => [...prev,url])
                })
            });
        }).catch((error) => {
            console.log(error)
        });
    },[])

    return (
      <div>
        <Navbar/>

        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      {/* We've used 3xl here, but feel free to try other max-widths based on your needs */}
            <div className="mx-auto max-w-3xl">
                    <h1 className="mt-20 font-bold text-2xl py-10">Restaurant</h1>
                <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                    {restaurantImages !== null && restaurantImages.map((file) => (
                    <li key={file.source} className="relative">
                    
                        <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                        <img src={`${file}`} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                        <button type="button" className="absolute inset-0 focus:outline-none">
                            <span className="sr-only">View details for {file.title}</span>
                        </button>
                        </div>
                        <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p>
                        <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p>
                    </li>
                    ))}
                </ul>

                <h1 className="mt-20 font-bold text-2xl py-10">Rooms</h1>
                <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
                    {roomImages !== null && roomImages.map((file) => (
                    <li key={file.source} className="relative">
                    
                        <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                        <img src={`${file}`} alt="" className="pointer-events-none object-cover group-hover:opacity-75" />
                        <button type="button" className="absolute inset-0 focus:outline-none">
                            <span className="sr-only">View details for {file.title}</span>
                        </button>
                        </div>
                        <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">{file.title}</p>
                        <p className="pointer-events-none block text-sm font-medium text-gray-500">{file.size}</p>
                    </li>
                    ))}
                </ul>
            </div>
        </div>
        
      </div>
    )
  }
  