export const posts = [
    {
      id: 1,
      title: 'Deluxe Room with Partial View',
      href: '#',
      description:
        "Discover comfort and elegance in our deluxe room with a partial view, perfectly situated in Gangtok. Adorned with modern amenities, this room offers a captivating glimpse of the city's charm and natural beauty. Enjoy the tranquility and luxury while savoring the scenic delights that make Gangtok truly enchanting.",
      imageUrl:
        'room1.jpeg',
      images: [
        {
          id: 1,
          name: 'Angled view',
          src: 'room1.jpeg',
          alt: 'Angled front view with bag zipped and handles upright.',
        },
        {
          id: 2,
          name: 'Angled view',
          src: 'hotel-heliaR1.jpg',
          alt: 'Angled front view with bag zipped and handles upright.',
        },
        {
          id: 3,
          name: 'Angled view',
          src: 'bathroom1.jpeg',
          alt: 'Angled front view with bag zipped and handles upright.',
        },
        // More images...
      ],
      area: '252 sq.ft',
      type:'Double Bed',
      category: { title: 'Mountail/Hill View', href: '#' },
      price:'2000',
      details: [
        {
          name: 'Features',
          items: [
            'Wi-Fi',
            'Iron/Ironing Board',
            '24-hour Room Service',
            'Bathroom',
            '24-hour Housekeeping',
            'In-room Dining',
          ],
        },
        // More sections...
      ],
    },

    {
        id: 2,
        title: 'Deluxe Mountain View Room with Hill View',
        href: '#',
        description:
          "Experience comfort and elegance in our deluxe room with a partial mountain view, nestled in the heart of Gangtok. Adorned with modern amenities, this room offers a captivating glimpse of the city's charm against the backdrop of majestic peaks. Relax in luxury while savoring the serene beauty of Gangtok's mesmerizing mountain scenery.",
        imageUrl:
        'hotel-heliaR2.jpg',
        images: [
          {
            id: 1,
            name: 'Angled view',
            src: 'room2.jpeg',
            alt: 'Angled front view with bag zipped and handles upright.',
          },
          {
            id: 2,
            name: 'Angled view',
            src: 'room3.jpeg',
            alt: 'Angled front view with bag zipped and handles upright.',
          },
          {
            id: 3,
            name: 'Angled view',
            src: 'bathroom2.jpeg',
            alt: 'Angled front view with bag zipped and handles upright.',
          },
          // More images...
        ],
        area: '252 sq.ft',
        type:'Double Bed',
        category: { title: 'Mountail/Hill View', href: '#' },
        price:'2000',
        details: [
          {
            name: 'Features',
            items: [
              'Wi-Fi',
              'Iron/Ironing Board',
              '24-hour Room Service',
              'Bathroom',
              '24-hour Housekeeping',
              'In-room Dining',
            ],
          },
          // More sections...
        ],
      },

      {
        id: 3,
        title: 'Attic Executive Room with Hill View',
        href: '#',
        description:
          "Indulge in a unique experience with our exclusive attic room featuring a private balcony. Nestled atop our establishment, this charming space offers a cozy retreat with a touch of elegance. Adorned with modern amenities, the attic room provides a private sanctuary, while the attached balcony offers a picturesque view, allowing you to immerse yourself in the beauty of Gangtok from a serene vantage point. Whether you're basking in the comfort of your room or enjoying the fresh mountain air on your balcony, this exclusive attic retreat promises a delightful stay filled with tranquility and style.",
        imageUrl:
          'suit.jpeg',
        images: [
          {
            id: 1,
            name: 'Angled view',
            src: 'hotel-heliaRoom2.jpg',
            alt: 'Angled front view with bag zipped and handles upright.',
          },
          // More images...
        ],
        area: '432 sq.ft',
        type:'Double Bed',
        category: { title: 'Mountail/Hill View', href: '#' },
        price:'2000',
        details: [
          {
            name: 'Features',
            items: [
              'Wi-Fi',
              'Iron/Ironing Board',
              '24-hour Room Service',
              'Bathroom',
              '24-hour Housekeeping',
              'In-room Dining',
            ],
          },
          // More sections...
        ],
      },
    // More posts...
  ]
