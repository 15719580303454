import React from "react";
import LandingPage from "./pages/LandingPage";
import { Routes,Route } from "react-router-dom";
import { Rooms } from "./pages/Rooms";
import BookingPage from "./pages/BookingPage";
import AdminPage from "./admin/AdminPage";
import Checkout from "./pages/Checkout";
import Gallery from "./pages/Gallery";
import ContactUs from "./pages/ContactUs";
import Login from "./pages/Login";
import Protected from "./protected";

export default function App(){
  return(
    <Routes>
      <Route path='/' element={<LandingPage/>}/>
      <Route path='Rooms' element={<Rooms/>}/>
      <Route path='BookingPage/:id' element={<BookingPage/>}/>
      
      <Route path='checkout' element={<Checkout/>}/>
      <Route path='gallery' element={<Gallery/>}/>
      <Route path='contactus' element={<ContactUs/>}/>
      <Route path='login' element={<Login/>}/>
      <Route element={<Protected/>}>
        <Route path='toAdminPanel' element={<AdminPage/>}/>
      </Route>
    </Routes>
  )
}

