import { Outlet,Navigate } from "react-router-dom";

const Protected = () => {

    const state = JSON.parse(localStorage.getItem('authStateLocal'))

    return(
      state ? <Outlet/> : <Navigate to='/login' replace/>
    )
  
};
export default Protected;