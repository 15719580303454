import { getDocs,query,where,collection } from "firebase/firestore"
import { useEffect, useState } from "react"
import { database } from "../../firebaseConfig"

const people = [
    { name: 'Lindsay Walton', title: 'Front-end Developer', email: 'lindsay.walton@example.com', role: 'Member' },
    // More people...
  ]
  
  export default function ViewBookings() {

    const [data,setData] = useState([])
    const [search,setSearch] = useState('91')
    const [requestData,setRequested] = useState(null)

    const options = {
        day: 'numeric',
        month: 'short',
        year:'numeric'
      };

    useEffect(() =>{
        const getData = async() =>{
            const ref = collection(database,'Bookings')
            await getDocs(ref)
            .then((snapshot) =>{
                let temp = []
                
                snapshot.docs.forEach((doc) =>{
                    const copyValue = doc.data()
                    const timestampInSecondsStart = copyValue.date.startDate.seconds;
                    const timestampInSecondsEnd = copyValue.date.endDate.seconds;
                    const millisecondsStart = timestampInSecondsStart * 1000; // Convert seconds to milliseconds
                    const millisecondsEnd = timestampInSecondsEnd * 1000; // Convert seconds to milliseconds

                    const dateStart = new Date(millisecondsStart);
                    const dateEnd = new Date(millisecondsEnd);
                    const formattedDateStart = dateStart.toLocaleDateString(undefined, options);
                    const formattedDateEnd = dateEnd.toLocaleDateString(undefined, options);
                    copyValue.date.startDate = formattedDateStart
                    copyValue.date.endDate = formattedDateEnd
                    temp.push(copyValue)
                })
                setData(temp)
            })
            .catch(err =>{
                console.log(err)
            })
        }
        getData()
    },[])

    const handleSearch = () =>{
        const ref = collection(database,'Bookings')
        const q = query(ref,where('phoneNumber','==',search))
        getDocs(q)
        .then(snapshot =>{
            snapshot.docs.forEach(doc =>{
                    const copyValue = doc.data()
                    const timestampInSecondsStart = copyValue.date.startDate.seconds;
                    const timestampInSecondsEnd = copyValue.date.endDate.seconds;
                    const millisecondsStart = timestampInSecondsStart * 1000; // Convert seconds to milliseconds
                    const millisecondsEnd = timestampInSecondsEnd * 1000; // Convert seconds to milliseconds

                    const dateStart = new Date(millisecondsStart);
                    const dateEnd = new Date(millisecondsEnd);
                    const formattedDateStart = dateStart.toLocaleDateString(undefined, options);
                    const formattedDateEnd = dateEnd.toLocaleDateString(undefined, options);
                    copyValue.date.startDate = formattedDateStart
                    copyValue.date.endDate = formattedDateEnd
                setRequested(copyValue)
            })
            console.log(requestData)
        })
    }

    return (
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 text-gray-900">Users</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the users in your account including their name, title, email and role.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Add user
            </button>
          </div>
        </div>

        <div className="mt-10 flex gap-x-4">
            <input
                type='search'
                placeholder="Search by Phone Number"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <button
              type="button"
              onClick={handleSearch}
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Search
            </button>
        </div>
        {requestData !== null && 
            (
                <div>
                    <h1>
                        name: {requestData.firstName} {requestData.lastName}
                    </h1>
                    <h1>From: {requestData.date.startDate}</h1>
                    <h1>To: {requestData.date.endDate}</h1>
                </div>
            )
        }

        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr className="divide-x divide-gray-200">
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      Name
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Phone Number
                    </th>
                    <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                      Room
                    </th>
                    <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-0">
                      Arrival Date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.map((person) => (
                    <tr key={person.email} className="divide-x divide-gray-200">
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                        {person.firstName} {person.lastName}
                      </td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.phoneNumber}</td>
                      <td className="whitespace-nowrap p-4 text-sm text-gray-500">{person.room}</td>
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-0"><span className="text-black font-semibold">From:</span>{person.date.startDate} <span className="text-black font-semibold">To:</span>{person.date.endDate} </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
  