import { Navbar } from "../components/Navbar"
import { IoPricetag } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import { posts } from "../data/data";
import { useEffect, useState } from "react";
import { collection, getDoc, getDocs } from "firebase/firestore";
import { database } from "../firebaseConfig";
import { Footer } from "../components/Footer";

// const posts = [
//     {
//       id: 1,
//       title: 'Deluxe Room with Partial View',
//       href: '#',
//       description:
//         'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
//       imageUrl:
//         'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
//       area: '252 sq.ft',
//       type:'Double Bed',
//       category: { title: 'Mountail/Hill View', href: '#' },
//       price:'2000'
//     },

//     {
//         id: 2,
//         title: 'Deluxe Mountain View Room with Hill View',
//         href: '#',
//         description:
//           'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
//         imageUrl:
//           'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
//         area: '252 sq.ft',
//         type:'Double Bed',
//         category: { title: 'Mountail/Hill View', href: '#' },
//         price:'2000'
//       },

//       {
//         id: 3,
//         title: 'Attic Executive Room with Hill View',
//         href: '#',
//         description:
//           'Illo sint voluptas. Error voluptates culpa eligendi. Hic vel totam vitae illo. Non aliquid explicabo necessitatibus unde. Sed exercitationem placeat consectetur nulla deserunt vel. Iusto corrupti dicta.',
//         imageUrl:
//           'https://images.unsplash.com/photo-1496128858413-b36217c2ce36?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3603&q=80',
//         area: '432 sq.ft',
//         type:'Double Bed',
//         category: { title: 'Mountail/Hill View', href: '#' },
//         price:'2000'
//       },
//     // More posts...
//   ]

export const Rooms = () =>{
    const [allPrices,setPrices] = useState([])
    const navigate = useNavigate()
    useEffect(() => {
      window.scrollTo(0, 0); // Scrolls to the top of the page on component mount or update
    }, []); 

    useEffect(() =>{
      const ref = collection(database,'Room_rates')
      getDocs(ref)
      .then((snapshot) =>{
        let temp = []
        snapshot.docs.forEach(doc =>{
          temp.push(doc.data())
        })
        setPrices(temp)
      })
    },[])
    const snippedPrice = allPrices.map(item =>{
      return item.price
    })

    return(
        <div>
            <Navbar/>
            <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Hotel Helia Rooms</h2>
            <p className="mt-2 text-lg leading-8 text-gray-600">
            Discover your perfect room for an unparalleled stay at Hotel Helia.
            </p>
          </div>
          <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
            {posts.map((post,index) => (
              <article key={post.id} className="flex flex-col items-start justify-between">
                <div className="relative w-full">
                  <img
                    src={require(`../images/${post.imageUrl}`)}
                    alt=""
                    className="aspect-[16/9] w-full rounded-2xl bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2]"
                  />
                  <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
                </div>
                <div className="max-w-xl">
                  <div className="mt-8 flex items-center gap-x-4 text-xs">
                      <GoDotFill/>{post.area}
                    <span
                      className="relative flex gap-x-4 z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600"
                    >
                      <GoDotFill/>{post.category.title}
                    </span>

                    <span
                      className="relative flex gap-x-4 z-10 rounded-full bg-gray-50 px-3 py-1.5 font-medium text-gray-600"
                    >
                      <GoDotFill/>{post.type}
                    </span>
                  </div>
                  <div className="group relative">
                    <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                      <a href={post.href}>
                        <span className="absolute inset-0" />
                        {post.title}
                      </a>
                    </h3>
                    <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">{post.description}</p>
                  </div>
                  <div className="relative mt-8 flex items-center gap-x-4">
                    <button onClick={() => navigate(`/BookingPage/${post.id}`)} className="text-gray-600 flex gap-1 items-center border border-gray-600 rounded-lg w-full p-2 justify-center"><IoPricetag/>Rs {snippedPrice[index]}</button>
                  </div>
                </div>
              </article>
            ))}
          </div>
        </div>
      </div>
      <Footer/>
        </div>
        
    )
}
  