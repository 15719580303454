/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Disclosure, RadioGroup, Tab } from '@headlessui/react'
import { StarIcon } from '@heroicons/react/20/solid'
import { HeartIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline'
import {Navbar} from '../components/Navbar'
import { posts } from '../data/data'
import {  DateRange } from 'react-date-range';
import { addDoc,collection, doc, getDoc } from 'firebase/firestore'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { database } from '../firebaseConfig'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import { Footer } from '../components/Footer'

// const product = {
//   name: 'Zip Tote Basket',
//   price: '$140',
//   rating: 4,
//   images: [
//     {
//       id: 1,
//       name: 'Angled view',
//       src: 'https://tailwindui.com/img/ecommerce-images/product-page-03-product-01.jpg',
//       alt: 'Angled front view with bag zipped and handles upright.',
//     },
//     // More images...
//   ],
//   colors: [
//     { name: 'Washed Black', bgColor: 'bg-gray-700', selectedColor: 'ring-gray-700' },
//     { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
//     { name: 'Washed Gray', bgColor: 'bg-gray-500', selectedColor: 'ring-gray-500' },
//   ],
//   description: `
//     <p>The Zip Tote Basket is the perfect midpoint between shopping tote and comfy backpack. With convertible straps, you can hand carry, should sling, or backpack this convenient and spacious bag. The zip top and durable canvas construction keeps your goods protected for all-day use.</p>
//   `,
//   details: [
//     {
//       name: 'Features',
//       items: [
//         'Multiple strap configurations',
//         'Spacious interior with top zip',
//         'Leather handle and tabs',
//         'Interior dividers',
//         'Stainless strap loops',
//         'Double stitched construction',
//         'Water-resistant',
//       ],
//     },
//     // More sections...
//   ],
// }

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const infoTemplate = {
  firstName:'',
  lastName:'',
  email:'',
  date:'',
  phoneNumber:'91',
  room:"",
  TotalRooms:''
}

export default function BookingPage() {
  const {id} = useParams()
  const [product,setProduct] = useState(posts[id - 1])
  const [date,setDate] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  })
  const [numberofDays,setNumber] = useState(0)
  const [roomPrice,setRoomPrice] = useState(0)
  const [newPrice,setPrice] = useState(roomPrice)
  const [userInfo,setUserInfo] = useState(infoTemplate)
  const [noRooms,setNoRooms] = useState(1)

  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page on component mount or update
  }, []); 

  useEffect(() => {
    const ref = doc(database,'Room_rates',id)
    getDoc(ref)
    .then(snapshot =>{
      const data = snapshot.data()
      setRoomPrice(data.price)
    })
  },[])
  
  useEffect(() =>{
    const differenceInMilliseconds = date.endDate - date.startDate;

    const millisecondsInADay = 1000 * 60 * 60 * 24;
    setNumber((differenceInMilliseconds / millisecondsInADay) + 1);
  },[date])
  
  useEffect(() =>{
    let tempPrice = roomPrice
    tempPrice = tempPrice * numberofDays * noRooms
    setPrice(tempPrice)
  },[numberofDays,noRooms])

  const subRooms = () =>{
    if(noRooms === 1) return
    setNoRooms(prev => prev -1)
  }

  const addRooms = () =>{
    if(noRooms === 4) return
    setNoRooms(prev => prev + 1)
  }

  const TotalRooms = () =>{
    return(
      <section className='flex gap-x-2 items-center'>
        <button onClick={subRooms} className='p-2 bg-gray-100 shadow-md text-bold'>-</button>
        {noRooms}
        <button onClick={addRooms} className='p-2 bg-gray-100 shadow-md text-bold'>+</button>
      </section>
    )
  }
 

  const selectedDates = (ranges) =>{
    setDate(ranges.selection)
  }

  const changeHandler = (e) =>{
    const {id,value} = e.target
    setUserInfo((prev) =>({
      ...prev,
      [id]:value
    }))
  }

  const handleSubmit = async (e) =>{
    e.preventDefault()

    const collRef = collection(database,'Bookings')
    await addDoc(collRef,{
      ...userInfo,
      date,
      amount:newPrice,
      room:product.title,
      TotalRooms:noRooms
    })
    .then(() =>{
      navigate('/checkout')
    })
  }

  const handlePhoneNumber = (value) =>{
    setUserInfo(prev => ({
        ...prev,
        phoneNumber:value
    }))
}

  // const disabledDates = [
  //   new Date('2023-11-25'),
  //   new Date('2023-11-27')
  // ]

  return (
    <div className="bg-white">
      <Navbar/>
      <div className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8">
          {/* Image gallery */}
          <Tab.Group as="div" className="flex flex-col-reverse">
            {/* Image selector */}
            <div className="mx-auto mt-6  w-full max-w-2xl sm:block lg:max-w-none">
              <Tab.List className="grid grid-cols-4 gap-6">
                {product.images.map((image) => (
                  <Tab
                    key={image.id}
                    className="relative flex h-24 cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50 focus:outline-none focus:ring focus:ring-opacity-50 focus:ring-offset-4"
                  >
                    {({ selected }) => (
                      <>
                        <span className="sr-only">{image.name}</span>
                        <span className="absolute inset-0 overflow-hidden rounded-md">
                          <img src={require(`../images/${image.src}`)} alt="" className="h-full w-full object-cover object-center" />
                        </span>
                        <span
                          className={classNames(
                            selected ? 'ring-indigo-500' : 'ring-transparent',
                            'pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2'
                          )}
                          aria-hidden="true"
                        />
                      </>
                    )}
                  </Tab>
                ))}
              </Tab.List>
            </div>

            <Tab.Panels className="aspect-h-1 aspect-w-1 w-full">
              {product.images.map((image) => (
                <Tab.Panel key={image.id}>
                  <img
                    src={require(`../images/${image.src}`)}
                    alt={image.alt}
                    className="h-full w-full object-cover object-center sm:rounded-lg"
                  />
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>

          {/* Product info */}
          <div className="mt-10 px-4 sm:mt-16 sm:px-0 lg:mt-0">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">{product.title}</h1>

            <div className="mt-3">
              <h2 className="sr-only">Product information</h2>
              <p className="text-3xl tracking-tight text-gray-900">Rs {roomPrice}/<span className='text-xs'>per nignt</span></p>
            </div>

            <div className="mt-6">
              <h3 className="sr-only">Description</h3>

              <div
                className="space-y-6 text-base text-gray-700"
                dangerouslySetInnerHTML={{ __html: product.description }}
              />
            </div>

            <section aria-labelledby="details-heading" className="mt-12">
              <h2 id="details-heading" className="sr-only">
                Additional details
              </h2>

              <div className="divide-y divide-gray-200 border-t">
                {product.details.map((detail) => (
                  <Disclosure as="div" key={detail.name}>
                    {({ open }) => (
                      <>
                        <h3>
                          <Disclosure.Button className="group relative flex w-full items-center justify-between py-6 text-left">
                            <span
                              className={classNames(open ? 'text-indigo-600' : 'text-gray-900', 'text-sm font-medium')}
                            >
                              {detail.name}
                            </span>
                            <span className="ml-6 flex items-center">
                              {open ? (
                                <MinusIcon
                                  className="block h-6 w-6 text-indigo-400 group-hover:text-indigo-500"
                                  aria-hidden="true"
                                />
                              ) : (
                                <PlusIcon
                                  className="block h-6 w-6 text-gray-400 group-hover:text-gray-500"
                                  aria-hidden="true"
                                />
                              )}
                            </span>
                          </Disclosure.Button>
                        </h3>
                        <Disclosure.Panel as="div" className="prose prose-sm pb-6">
                          <ul role="list">
                            {detail.items.map((item) => (
                              <li key={item}>{item}</li>
                            ))}
                          </ul>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                ))}
              </div>
            </section>

            <div>
            <form onSubmit={handleSubmit}>
                <div className="space-y-12">

                  <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
                    <div>
                      <h2 className="text-base font-semibold leading-7 text-gray-900">Personal Information</h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600">Use a permanent address where you can receive mail.</p>
                    </div>

                    <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 md:col-span-2">
                      <div className="sm:col-span-3">
                        <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-gray-900">
                          First name
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={changeHandler}
                            value={userInfo.firstName}
                            type="text"
                            name="first-name"
                            id="firstName"
                            autoComplete="given-name"
                            required={true}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-3">
                        <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-gray-900">
                          Last name
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={changeHandler}
                            value={userInfo.lastName}
                            type="text"
                            name="last-name"
                            id="lastName"
                            autoComplete="family-name"
                            required={true}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                          Email address
                        </label>
                        <div className="mt-2">
                          <input
                            onChange={changeHandler}
                            value={userInfo.email}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            required={true}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-4">
                        <label htmlFor="phoneNumber" className="block text-sm font-medium leading-6 text-gray-900">
                          Phone Number
                        </label>
                        <PhoneInput
                        country={'in'}
                        value={userInfo.phoneNumber}
                        id='phoneNumber'
                        onChange={phone => handlePhoneNumber(phone)}
                       />
                        <div className='mt-2'>
                          <span>Number of Rooms:</span><TotalRooms/>
                        </div>
                      </div>


                      {/* <div className="sm:col-span-3">
                        <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                          Count
                        </label>
                        <div className="mt-2">
                          <select
                            id="country"
                            name="country"
                            autoComplete="country-name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                          >
                            <option>United States</option>
                            <option>Canada</option>
                            <option>Mexico</option>
                          </select>
                        </div>
                      </div> */}
                     
                    </div>
                      <div className='flex flex-col gap-y-4'>
                        <label>Select Stay Dates</label>
                      <DateRange
                        ranges={[date]}
                        onChange={selectedDates}
                        minDate={new Date()}
                        // disabledDates={disabledDates}
                      />

                        <h1 className="block text-sm font-medium leading-6 text-gray-900">
                          Total: Rs {newPrice} <span className='text-xs'> *({numberofDays} nights)</span>
                        </h1>

                      </div>
                  </div>
                </div>

                <div className="rounded-md bg-yellow-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">Property Rules</h3>
                      <div className="mt-2 text-sm text-yellow-700">
                        <h1 className='font-semibold'>Check-in: 2 PM Check-out: 12 PM</h1>
                        <ul className='list-disc'>
                          <li>Guests below 18 years of age are not allowed at the property</li>
                          <li>Passport, Aadhar, Govt. ID and Driving License are accepted as ID proof(s)</li>
                          <li>Pets are not allowed.</li>
                          <li>Outside food is not allowed</li>
                        </ul>
                        <h1 className='font-semibold'>Guest Profile</h1>
                        <ul className='list-disc'>
                          <li>Unmarried couples allowed</li>
                          <li>Groups with only male guests are not allowed at this property</li>
                          <li>Guests below 18 years of age are not allowed at the property.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Book Now
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Footer/>
    </div>
  )
}
